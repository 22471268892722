import React from "react";
import { CancelPaymentModel } from "../../models/CancelPaymentModel";
import { ChannelModel } from "../../models/ChannelModel";
import { DoPaymentModel } from "../../models/DoPaymentModel";
import { PaymentResultModel } from "../../models/PaymentResultModel";
import { TransactionModel } from "../../models/TransactionModel";
import { PaymentProvider } from "./PaymentProvider";
import { MidtransDtoInterface } from "./interfaces/donation-result.interface";
import axios from "axios";
import { CompanySingleResultInterface } from "../../models/CompanyModel";
import { DonationSingleResultInterface } from "./interfaces/donation-single-result.interface";
import { IPaymuPaymentModel } from "./interfaces/ipaymu-body-request.interface";
import { IPayResponse } from "./interfaces/ipaymu-result.interface";
import { WalletDetalResultInterface } from "./interfaces/wallet-detail-result.interface";
import { BalanceWalletResultInterface } from "./interfaces/balance-wallet-result.interface";
import { GeneralModel } from "../../models/GeneralModel";
import { NicepayResponse } from "./interfaces/nicepay-result.interface";
import {
  NicepayPaymentModel,
  NicepayStatusModel,
} from "./interfaces/nicepay-body-request.interface";
import { NicepayStatusResponseModel } from "../../models/CheckSatusNicepayModel";
import { NicepayStatusResult } from "./interfaces/nicepay-check-status-result.interface";
import { TransactionByInvoiceCodeResult } from "./interfaces/transaction-by-invoice-code-result.interface";

export type PaymentContextType = {
  paymentResult: PaymentResultModel | undefined;
  paymentResultIpaymu?: IPayResponse;
  paymentResultNicepay?: NicepayResponse;
  loading: boolean;
  loadingNicepay: boolean;
  transactionModel: TransactionModel | undefined;
  cancelPaymentModel: CancelPaymentModel | undefined;
  transactionStatusModel?: CancelPaymentModel;
  nicepayStatusPayment?: NicepayStatusResponseModel;
  donationDetail?: DonationSingleResultInterface;
  transactionDetail?: TransactionByInvoiceCodeResult;
  donationDetailForStatus?: DonationSingleResultInterface;
  transactionDetailForStatus?: TransactionByInvoiceCodeResult;
  walletDetail?: WalletDetalResultInterface;
  walletDetailStatus?: WalletDetalResultInterface;
  balanceWallet?: BalanceWalletResultInterface;
  deductBalanceResult?: GeneralModel;
  deductLoading?: boolean;
  donationDetailLoading?: boolean;
  transactionDetailLoading?: boolean;
  setDonationResultV2?: (donationResul: DonationSingleResultInterface) => void;
  doPayment: (channel: ChannelModel, midtransDto: MidtransDtoInterface) => void;
  doPaymentV2: (channel: ChannelModel) => void;
  doPaymentCommerce: (channel: ChannelModel) => void;
  doPaymentTopupV2: (channel: ChannelModel) => void;
  doPaymentIpaymu: (channel: ChannelModel) => void;
  doPaymentNicepay: (channel: ChannelModel) => void;
  checkStatusNicepay: () => void;
  cancelPayment: (orderId: string) => void;
  initPayment: (
    donationId: string,
    paymentId: string,
    vaNumber: string
  ) => void;
  transactionStatus: (orderId: string) => void;
  transactionStatusCommerce: (orderId: string) => void;
  getDonationDetail: (invoiceCode: string) => void;
  getTransactionDetail: (invoiceCode: string) => void;
  getTransactionDetailForStatus: (invoiceCode: string) => void;
  getDonationDetailForStatus: (invoiceCode: string) => void;
  getWalletDetail: (invoiceCode: string) => void;
  getWalletDetailStatus: (invoiceCode: string) => void;
  getBalanceWalletUser: (userId: string) => void;
  doDeductBalance: (
    userId: string,
    donationId: string,
    donationAmount: number,
    description: string
  ) => void;
};

export const PaymentContext = React.createContext<PaymentContextType | null>(
  null
);

export const PaymentContextProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const [paymentResult, setPaymentResult] =
    React.useState<PaymentResultModel>();
  const [paymentResultIpaymu, setPaymentResultIpaymu] =
    React.useState<IPayResponse>();
  const [paymentResultNicepay, setPaymentResultNicepay] =
    React.useState<NicepayResponse>();
  const [transactionModel, setTransactionModel] =
    React.useState<TransactionModel>();
  const [cancelPaymentModel, setCancelPaymentModel] =
    React.useState<CancelPaymentModel>();
  const [transactionStatusModel, setTransactionStatusModel] =
    React.useState<CancelPaymentModel>(undefined);
  const [nicepayStatusPayment, setNicepayStatusPayment] =
    React.useState<NicepayStatusResult>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loadingNicepay, setLoadingNicepay] = React.useState<boolean>(true);
  const [donationDetail, setDonationDetail] =
    React.useState<DonationSingleResultInterface>(undefined);
  const [donationDetailForStatus, setDonationDetailForStatus] =
    React.useState<DonationSingleResultInterface>(undefined);
  const [walletDetail, setWalletDetail] =
    React.useState<WalletDetalResultInterface>(undefined);
  const [walletDetailStatus, setWalletDetailStatus] =
    React.useState<WalletDetalResultInterface>(undefined);
  const [balanceWallet, setBalanceWallet] =
    React.useState<BalanceWalletResultInterface>(undefined);
  const [deductBalanceResult, setDeductBalanceResult] =
    React.useState<GeneralModel>(undefined);
  const [deductLoading, setDeductLoading] = React.useState<boolean>(false);
  const [donationDetailLoading, setDonationDetailLoading] =
    React.useState<boolean>(false);

  const [transactionDetailLoading, setTransactionDetailLoading] =
    React.useState<boolean>(false);
  const [transactionDetail, setTransactionDetail] =
    React.useState<TransactionByInvoiceCodeResult>(undefined);
  const [transactionDetailForStatus, setTransactionDetailForStatus] =
    React.useState<TransactionByInvoiceCodeResult>(undefined);

  const setDonationResultV2 = (
    donationResult: DonationSingleResultInterface
  ) => {
    setDonationDetail(donationResult);
  };

  const initPayment = async (
    donationId: string,
    paymentId: string,
    vaNumber: string
  ) => {
    var paymentProvider: PaymentProvider = new PaymentProvider();
    const response = await paymentProvider.initPayment(
      donationId,
      paymentId,
      vaNumber
    );
    console.log(response);
  };

  const transactionStatus = async (orderId: string) => {
    var paymentProvider: PaymentProvider = new PaymentProvider();
    const response = await paymentProvider.transactionStatus(orderId);
    setTransactionStatusModel(response);
  };

  const transactionStatusCommerce = async (orderId: string) => {
    var paymentProvider: PaymentProvider = new PaymentProvider();
    const response = await paymentProvider.transactionStatusCommerce(orderId);
    setPaymentResult(response);
  };

  const doPaymentCommerce = async (channel: ChannelModel) => {
    const company = await axios.get(
      `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
    );
    const response = company.data as CompanySingleResultInterface;

    setLoading(true);

    var paymentProvider: PaymentProvider = new PaymentProvider();

    console.log("zxczxc", transactionDetail);

    try {
      let doPaymentModel: DoPaymentModel = undefined;

      if (channel.payment_type === "echannel") {
        doPaymentModel = {
          payment_type: channel.payment_type,
          transaction_details: {
            gross_amount: transactionDetail?.data?.total_amount,
            order_id: transactionDetail?.data?.invoice_code,
          },
          customer_details: {
            email: transactionDetail?.data?.customer_email,
            first_name: transactionDetail?.data?.customer_name,
            last_name: "",
            phone: transactionDetail?.data?.customer_phone,
          },
          // item_details: midtransDto.item_details,
          echannel: channel.echannel,
        };
      } else {
        doPaymentModel = {
          payment_type: channel.payment_type,
          transaction_details: {
            gross_amount: transactionDetail?.data?.total_amount,
            order_id: transactionDetail?.data?.invoice_code,
          },
          customer_details: {
            email: transactionDetail?.data?.customer_email,
            first_name: transactionDetail?.data?.customer_name,
            last_name: "",
            phone: transactionDetail?.data?.customer_phone,
          },
          // item_details: midtransDto?.,
          bank_transfer: {
            bank: channel.code,
          },
        };
      }

      switch (channel.payment_type) {
        case "cstore":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            cstore: {
              store: channel.code,
            },
          };
          break;
        case "gopay":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            gopay: {
              enable_callback: true,
              callback_url: response?.data?.platform_donation,
            },
          };
          break;
        case "shopeepay":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            payment_type: "shopeepay",
            enabled_payments: ["shopeepay"],
            shopeepay: {
              callback_url: response?.data?.platform_donation,
            },
          };
          break;
        case "qris":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            payment_type: "qris",
          };
          break;
      }

      let result = await paymentProvider.doPayment(doPaymentModel);
      setPaymentResult(result);
      setLoading(false);
    } catch (e) {
      setPaymentResult(e);
      setLoading(false);
    }
  };

  const doPaymentV2 = async (channel: ChannelModel) => {
    const company = await axios.get(
      `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
    );
    const response = company.data as CompanySingleResultInterface;

    setLoading(true);

    var paymentProvider: PaymentProvider = new PaymentProvider();

    console.log("zxczxc", donationDetail);

    try {
      let doPaymentModel: DoPaymentModel = undefined;

      if (channel.payment_type === "echannel") {
        doPaymentModel = {
          payment_type: channel.payment_type,
          transaction_details: {
            gross_amount: donationDetail?.data?.amount,
            order_id: donationDetail?.data?.invoice_code,
          },
          customer_details: {
            email: donationDetail?.data?.customer_email,
            first_name: donationDetail?.data?.customer_name,
            last_name: "",
            phone: donationDetail?.data?.customer_phone,
          },
          // item_details: midtransDto.item_details,
          echannel: channel.echannel,
        };
      } else {
        doPaymentModel = {
          payment_type: channel.payment_type,
          transaction_details: {
            gross_amount: donationDetail?.data?.amount,
            order_id: donationDetail?.data?.invoice_code,
          },
          customer_details: {
            email: donationDetail?.data?.customer_email,
            first_name: donationDetail?.data?.customer_name,
            last_name: "",
            phone: donationDetail?.data?.customer_phone,
          },
          // item_details: midtransDto?.,
          bank_transfer: {
            bank: channel.code,
          },
        };
      }

      switch (channel.payment_type) {
        case "cstore":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            cstore: {
              store: channel.code,
            },
          };
          break;
        case "gopay":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            gopay: {
              enable_callback: true,
              callback_url: response?.data?.platform_donation,
            },
          };
          break;
        case "shopeepay":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            payment_type: "shopeepay",
            enabled_payments: ["shopeepay"],
            shopeepay: {
              callback_url: response?.data?.platform_donation,
            },
          };
          break;
        case "qris":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            payment_type: "qris",
          };
          break;
      }

      let result = await paymentProvider.doPayment(doPaymentModel);
      setPaymentResult(result);
      setLoading(false);
    } catch (e) {
      setPaymentResult(e);
      setLoading(false);
    }
  };

  const doPaymentTopupV2 = async (channel: ChannelModel) => {
    const company = await axios.get(
      `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
    );
    const response = company.data as CompanySingleResultInterface;

    setLoading(true);

    var paymentProvider: PaymentProvider = new PaymentProvider();

    console.log("zxczxc", donationDetail);

    try {
      let doPaymentModel: DoPaymentModel = undefined;

      if (channel.payment_type === "echannel") {
        doPaymentModel = {
          payment_type: channel.payment_type,
          transaction_details: {
            gross_amount: walletDetail?.data?.amount,
            order_id: walletDetail?.data?.invoice_code,
          },
          customer_details: {
            email: walletDetail?.data?.user_email,
            first_name: walletDetail?.data?.user_full_name,
            last_name: "",
            phone: walletDetail?.data?.user_phone_number,
          },
          // item_details: midtransDto.item_details,
          echannel: channel.echannel,
        };
      } else {
        doPaymentModel = {
          payment_type: channel.payment_type,
          transaction_details: {
            gross_amount: walletDetail?.data?.amount,
            order_id: walletDetail?.data?.invoice_code,
          },
          customer_details: {
            email: walletDetail?.data?.user_email,
            first_name: walletDetail?.data?.user_full_name,
            last_name: "",
            phone: walletDetail?.data?.user_phone_number,
          },
          // item_details: midtransDto?.,
          bank_transfer: {
            bank: channel.code,
          },
        };
      }

      switch (channel.payment_type) {
        case "cstore":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            cstore: {
              store: channel.code,
            },
          };
          break;
        case "gopay":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            gopay: {
              enable_callback: true,
              callback_url: response?.data?.platform_donation,
            },
          };
          break;
        case "shopeepay":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            payment_type: "shopeepay",
            enabled_payments: ["shopeepay"],
            shopeepay: {
              callback_url: response?.data?.platform_donation,
            },
          };
          break;
        case "qris":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            payment_type: "qris",
          };
          break;
      }

      let result = await paymentProvider.doPayment(doPaymentModel);
      setPaymentResult(result);
      setLoading(false);
    } catch (e) {
      setPaymentResult(e);
      setLoading(false);
    }
  };

  const doPayment = async (
    channel: ChannelModel,
    midtransDto: MidtransDtoInterface
  ) => {
    const company = await axios.get(
      `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
    );
    const response = company.data as CompanySingleResultInterface;

    setLoading(true);

    var paymentProvider: PaymentProvider = new PaymentProvider();

    try {
      let doPaymentModel: DoPaymentModel = undefined;

      if (channel.payment_type === "echannel") {
        doPaymentModel = {
          payment_type: channel.payment_type,
          transaction_details: {
            gross_amount: midtransDto?.transaction_details?.gross_amount,
            order_id: midtransDto?.transaction_details?.order_id,
          },
          customer_details: {
            email: midtransDto.customer_details?.email,
            first_name: midtransDto.customer_details?.first_name,
            last_name: midtransDto.customer_details?.last_name,
            phone: midtransDto.customer_details?.phone,
          },
          // item_details: midtransDto.item_details,
          echannel: channel.echannel,
        };
      } else {
        doPaymentModel = {
          payment_type: channel.payment_type,
          transaction_details: {
            gross_amount: midtransDto?.transaction_details?.gross_amount,
            order_id: midtransDto?.transaction_details?.order_id,
          },
          customer_details: {
            email: midtransDto?.customer_details?.email,
            first_name: midtransDto?.customer_details?.first_name,
            last_name: midtransDto?.customer_details?.last_name,
            phone: midtransDto?.customer_details?.phone,
          },
          // item_details: midtransDto?.,
          bank_transfer: {
            bank: channel.code,
          },
        };
      }

      switch (channel.payment_type) {
        case "cstore":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            cstore: {
              store: channel.code,
            },
          };
          break;
        case "gopay":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            gopay: {
              enable_callback: true,
              callback_url: response?.data?.platform_donation,
            },
          };
          break;
        case "shopeepay":
          delete doPaymentModel.bank_transfer;
          doPaymentModel = {
            ...doPaymentModel,
            shopeepay: {
              callback_url: response?.data?.platform_donation,
            },
          };
          break;
      }

      let result = await paymentProvider.doPayment(doPaymentModel);
      setPaymentResult(result);
      setLoading(false);
    } catch (e) {
      setPaymentResult(e);
      setLoading(false);
    }
  };

  const cancelPayment = async (orderId: string) => {
    try {
      var paymentProvider: PaymentProvider = new PaymentProvider();
      let response = await paymentProvider.cancelPayment(orderId);
      setCancelPaymentModel(response);
    } catch (e) {
      setCancelPaymentModel(undefined);
    }
  };

  const getDonationDetail = async (invoiceCode: string) => {
    try {
      setDonationDetailLoading(true);
      var paymentProvider: PaymentProvider = new PaymentProvider();
      const response = await paymentProvider.getDonationDetail(invoiceCode);
      setDonationDetail(response);
      setDonationDetailLoading(false);
    } catch (e) {
      setDonationDetail(undefined);
      setDonationDetailLoading(false);
    }
  };

  const getTransactionDetail = async (invoiceCode: string) => {
    try {
      setTransactionDetailLoading(true);
      var paymentProvider: PaymentProvider = new PaymentProvider();
      const response = await paymentProvider.getTransactionDetail(invoiceCode);
      setTransactionDetail(response);
      setTransactionDetailLoading(false);
    } catch (e) {
      setTransactionDetail(undefined);
      setTransactionDetailLoading(false);
    }
  };

  // const uploadProofOfPayment = async (invoiceCode: string) => {
  //   try {

  //   }
  // }

  const getDonationDetailForStatus = async (invoiceCode: string) => {
    try {
      var paymentProvider: PaymentProvider = new PaymentProvider();

      const response = await paymentProvider.getDonationDetail(invoiceCode);
      setDonationDetailForStatus(response);
    } catch (e) {
      setDonationDetailForStatus(undefined);
    }
  };

  const getTransactionDetailForStatus = async (invoiceCode: string) => {
    try {
      var paymentProvider: PaymentProvider = new PaymentProvider();

      const response = await paymentProvider.getTransactionDetail(invoiceCode);
      setTransactionDetailForStatus(response);
    } catch (e) {
      setTransactionDetailForStatus(undefined);
    }
  };

  const doPaymentIpaymu = async (channel: ChannelModel) => {
    const company = await axios.get(
      `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
    );
    const response = company.data as CompanySingleResultInterface;

    setLoading(true);

    var paymentProvider: PaymentProvider = new PaymentProvider();

    try {
      let customerEmail = response?.data?.email_smtp_user;
      if (donationDetail?.data?.customer_email !== "") {
        customerEmail = donationDetail?.data?.customer_email;
      }
      const ipaymuBodyReq: IPaymuPaymentModel = {
        name: donationDetail?.data?.customer_name,
        phone: donationDetail?.data?.customer_phone,
        email: customerEmail,
        amount: donationDetail?.data?.amount?.toString(),
        product: donationDetail?.data?.program_name,
        returnUrl: `${response?.data?.platform_donation}/payment/success`,
        cancelUrl: `${response?.data?.platform_donation}/payment/failed`,
        orderId: donationDetail?.data?.order_id,
        paymentMethod: channel?.payment_type,
        paymentChannel: channel?.code,
        comments: `Payment for ${donationDetail?.data?.program_name}`,
      };
      const result = await paymentProvider.initPaymentIPaymu(ipaymuBodyReq);
      console.log(result);
      setPaymentResultIpaymu(result);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setPaymentResultIpaymu(undefined);
      setLoading(false);
    }
  };

  const doPaymentNicepay = async (channel: ChannelModel) => {
    const company = await axios.get(
      `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
    );
    const response = company.data as CompanySingleResultInterface;

    setLoading(true);

    var paymentProvider: PaymentProvider = new PaymentProvider();

    try {
      const niceBodyReq: NicepayPaymentModel = {
        payMethod: channel.payment_type,
        currency: "IDR",
        amt: donationDetail?.data?.amount?.toString(),
        referenceNo: donationDetail?.data?.invoice_code,
        mitraCd: "",
        goodsNm: donationDetail?.data?.payment_name,
        cartData: "{}",
        billingNm: donationDetail?.data?.customer_name,
        billingPhone: donationDetail?.data?.customer_phone,
        billingEmail: donationDetail?.data?.customer_email,
        billingAddr: "",
        billingCity: "",
        billingState: "Jakarta",
        billingPostCd: "55555",
        billingCountry: "Indonesia",

        description:
          donationDetail?.data?.program?.description ||
          donationDetail?.data?.program_name,
        userSessionID:
          donationDetail?.data?.created_by || donationDetail?.data?.customer_id,
        bankCd: channel?.code,
      };
      const result = await paymentProvider.initPaymentNicepay(niceBodyReq);
      console.log(result);
      setPaymentResultNicepay(result);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setPaymentResultNicepay(undefined);
      setLoading(false);
    }
  };

  const checkStatusNicepay = async () => {
    setLoadingNicepay(true);

    var paymentProvider: PaymentProvider = new PaymentProvider();

    try {
      const niceBodyReq: NicepayStatusModel = {
        tXid: paymentResultNicepay?.data?.tXid,
        referenceNo: donationDetail?.data?.invoice_code,
        amt: donationDetail?.data?.amount?.toString(),
      };

      const result = await paymentProvider.initStatusNicepay(niceBodyReq);
      setNicepayStatusPayment(result);
      setLoadingNicepay(false);
    } catch (e) {
      console.log(e);
      setNicepayStatusPayment(undefined);
      setLoadingNicepay(false);
    }
  };

  const getWalletDetail = async (invoiceCode: string) => {
    try {
      var paymentProvider: PaymentProvider = new PaymentProvider();
      const response = await paymentProvider.checkWalletDetail(invoiceCode);
      setWalletDetail(response);
    } catch (e) {
      setWalletDetail(undefined);
    }
  };

  const getWalletDetailStatus = async (invoiceCode: string) => {
    try {
      var paymentProvider: PaymentProvider = new PaymentProvider();
      const response = await paymentProvider.checkWalletDetail(invoiceCode);
      setWalletDetailStatus(response);
    } catch (e) {
      setWalletDetailStatus(undefined);
    }
  };

  const getBalanceWalletUser = async (userId: string) => {
    try {
      var paymentProvider: PaymentProvider = new PaymentProvider();
      const response = await paymentProvider.balanceWalletUser(userId);
      setBalanceWallet(response);
    } catch (e) {
      setWalletDetailStatus(undefined);
    }
  };

  const doDeductBalance = async (
    userId: string,
    donationId: string,
    donationAmount: number,
    description: string
  ) => {
    try {
      setDeductLoading(true);
      var paymentProvider: PaymentProvider = new PaymentProvider();
      const response = await paymentProvider.deductWallet(
        userId,
        donationId,
        donationAmount,
        description
      );
      setDeductBalanceResult(response);
      setDeductLoading(false);
    } catch (e) {
      setDeductBalanceResult(undefined);
      setDeductLoading(false);
    }
  };

  return (
    <PaymentContext.Provider
      value={{
        paymentResult,
        loading,
        loadingNicepay,
        doPayment,
        transactionModel,
        cancelPayment,
        cancelPaymentModel,
        initPayment,
        transactionStatus,
        transactionStatusModel,
        nicepayStatusPayment,
        donationDetail,
        getDonationDetail,
        doPaymentV2,
        setDonationResultV2,
        doPaymentIpaymu,
        paymentResultIpaymu,
        doPaymentNicepay,
        checkStatusNicepay,
        paymentResultNicepay,
        getDonationDetailForStatus,
        donationDetailForStatus,
        walletDetail,
        walletDetailStatus,
        getWalletDetail,
        getWalletDetailStatus,
        doPaymentTopupV2,
        getBalanceWalletUser,
        balanceWallet,
        doDeductBalance,
        deductBalanceResult,
        deductLoading,
        donationDetailLoading,
        transactionDetail,
        transactionDetailLoading,
        getTransactionDetail,
        doPaymentCommerce,
        transactionStatusCommerce,
        transactionDetailForStatus,
        getTransactionDetailForStatus,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
